<script>
// Extensions
import View from '@/views/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
  name: 'Home',

  metaInfo() {
    return {
      meta: this.pageMeta,
    };
  },

  extends: View,

  mixins: [
    LoadSections([
      'hero',
      'features',
      'benefits',
      'posts',
      'customers',
      'info',
    ]),
  ],
  props: {
    id: {
      type: String,
      default: 'home',
    },
  },
  computed: {
    test() {
      return []
    },
    pageMeta() {
      const meta = [];
      meta.push({name: 'description', content: this.$t('section.home_description')});
      meta.push({property: 'og:type', content: 'website'});
      meta.push({
        property: 'og:url',
        content: `https://www.evaluate.se`
      });
      meta.push({property: 'og:site_name', content: 'Evaluate'});
      meta.push({property: 'og:title', content: `Evaluate – ${this.$t('brand.tagline')}`});
      meta.push({property: 'og:description', content: this.$t('section.home_description')});
      meta.push({
        property: 'og:image',
        content: 'https://storage.googleapis.com/evaluate-public/assets/img/evaluate_wall.jpg'
      });
      meta.push({property: 'og:locale', content: this.$i18n.locale === 'en' ? 'en-GB' : 'sv-SE'});
      return meta;
    },
  }

}
</script>
